import { Badge } from 'antd';
import type { BaseData, DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, NormalProgramme, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { getWarehouseBin } from '../../utils';
import type { Store } from './store';
import { ENUM_PROCESS_STATUS, enumType } from './store';
import type { PickMainItem } from './types';

export class InventoryStore {
  constructor(parent: Store) {
    this.parent = parent;
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/business/fault/st/type' })
      .then((info) => this.normalProgramme.filterItems.addDict({ stockTakeType: objToDict(info.data) }));

    getWarehouseBin()
      .then((warehouseBinIds) => this.normalProgramme.filterItems.addDict({ warehouseBinIds }));
  }

  public parent: Store;

  public mainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'stockTakeTypeName',
          name: '盘点类型',
          width: 100,
        },
        {
          key: 'processStatus',
          name: '处理状态',
          width: 150,
          formatter({ row }) {
            return (
              <Badge

                // @ts-ignore
                status={Object.values(enumType)
                  .find((item) => item.value == row.processStatus)?.status || 'default'}
                text={Object.values(enumType)
                  .find((item) => item.value == row.processStatus)?.label}
              />
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'skuNo',
          name: 'sku编码',
          width: 250,
        },
        {
          key: 'color',
          name: '颜色',
          width: 150,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 150,
        },
        {
          key: 'warehouseBinNo',
          name: '库位',
          width: 150,
        },
        {
          key: 'beforeNumber',
          name: '盘前数量',
          width: 100,
        },
        {
          key: 'realNumber',
          name: '实盘数量',
          width: 100,
        },
        {
          key: 'createUser',
          name: '创建人',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 200,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'taskStatus',
          name: '任务进度',
          width: 100,
          formatter({ row }) {
            return (
              <span>
                {ENUM_PROCESS_STATUS[row.taskStatus]?.label}
              </span>
            );
          },
        },
        {
          key: 'wmsStOrderNo',
          name: '盘点单编号',
          width: 200,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/exceptionHandling/index/inventoryStore',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<PickMainItem>>({
          url: '/api/cloud/wms/rest/business/fault/st/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme = new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'dateRange',
        field: 'dateValue',
        label: '创建时间',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'processStatus',
        label: '处理状态',
        data: Object.values(enumType),
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'stockTakeType',
        label: '盘点类型',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'sku编码',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'warehouseBinIds',
        label: '库位',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'taskStatus',
        label: '任务进度',
        data: Object.values(ENUM_PROCESS_STATUS),
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });
}
