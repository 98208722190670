import { action, observable } from 'mobx';
import React from 'react';
import { InventoryStore } from './inventoryStore';
import { PickStore } from './pickStore';

export const ENUM_PROCESS_STATUS = {
  '0': {
    value: '0',
    label: '未领取',
  },
  '2': {
    value: '2',
    label: '已领取',
  },
  '3': {
    value: '3',
    label: '已完成',
  },
};

export const enumType = {
  yes: {
    value: '1',
    label: '未处理',
    status: 'error',
  },
  no: {
    value: '2',
    label: '已处理',
    status: 'success',
  },
};

export const enumException = {
  pick: {
    value: 'pick',
    label: '拣货异常',
  },
  inventory: {
    value: 'inventory',
    label: '盘点差异',
  },
};

export class Store {
  constructor() {
    this.pickStore = new PickStore(this);
    this.inventoryStore = new InventoryStore(this);
    this.state = this.pickStore;
  }

  @observable public selectedKeys: string[] = [enumException.pick.value];

  @action public onSelect = (item) => {
    this.selectedKeys = item.selectedKeys || [];
    if (this.selectedKeys.includes(enumException.pick.value)) {
      this.state = this.pickStore;
    } else if (this.selectedKeys.includes(enumException.inventory.value)) {
      this.state = this.inventoryStore;
    }

    this.state.mainSubStructureModel.onRefresh();
  };

  public pickStore: PickStore;

  public inventoryStore: InventoryStore;

  @observable.ref public state: PickStore;
}

