import { Badge, message, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, NormalProgramme, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { LogTooltip } from '../../utils';
import type { Store } from './store';
import { ENUM_PROCESS_STATUS, enumType } from './store';
import type { PickMainItem } from './types';

export class PickStore {
  constructor(parent: Store) {
    this.parent = parent;
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public parent: Store;

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        permissionId: '2030',
        text: '转盘点任务',
        icon: 'icon-yc-zpdrw',
        handleClick: () => {
          const ids = this.mainSubStructureModel.gridModel.selectRows.map((item) => item.wmsBusinessFaultId)
            .join(',');
          if (!ids) {
            message.warning('请选择');
            return;
          }

          Modal.confirm({
            content: '确认转盘点任务?',
            onOk: () => request({
              url: '/api/cloud/wms/rest/business/fault/stock/take',
              method: 'post',
              data: { ids },
            })
              .then(() => {
                this.mainSubStructureModel.onQuery();
              }),
          });
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'processStatus',
          name: '处理状态',
          width: 150,
          formatter({ row }) {
            return (
              <Badge

                // @ts-ignore
                status={Object.values(enumType).find((item) => item.value == row.processStatus)?.status || 'default'}
                text={row.processStatusDesc}
              />
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.pic}/>;
          },
        },
        {
          key: 'skuNo',
          name: 'sku编码',
          width: 250,
        },
        {
          key: 'color',
          name: '颜色',
          width: 150,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 150,
        },
        {
          key: 'warehouseBinNo',
          name: '库位',
          width: 150,
        },
        {
          key: 'stockOutNumber',
          name: '缺货数量',
          width: 150,
        },
        {
          key: 'createUser',
          name: '创建人',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 200,
          formatter: ({ row }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'remark',
          name: '备注',
          width: 200,
        },
        {
          key: 'taskStatus',
          name: '任务进度',
          width: 100,
          formatter({ row }) {
            return (
              <span>
                {ENUM_PROCESS_STATUS[row.taskStatus]?.label}
              </span>
            );
          },
        },
        {
          key: 'wmsStOrderNo',
          name: '盘点单编号',
          width: 200,
        },
        {
          key: '日志',
          name: '日志',
          width: 50,
          formatter: ({ row }) => (
            <LogTooltip
              children={(
                <a>
                  详情
                </a>
              )}
              entityId={row.wmsBusinessFaultId}
              height={230}
              logBizModuleCode={80000}
              popoverProps={{
                title: '异常日志',
                trigger: 'click',
                placement: 'left',
              }}
              width={810}
            />
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsBusinessFaultId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/exceptionHandling/index/pick',
    },
    pageId: '4002',

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<PickMainItem>>({
          url: '/api/cloud/wms/rest/business/fault/pick/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme = new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'dateRange',
        field: 'dateValue',
        label: '创建时间',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'processStatus',
        label: '处理状态',
        data: Object.values(enumType),
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'sku编码',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'taskStatus',
        label: '任务进度',
        data: Object.values(ENUM_PROCESS_STATUS),
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });
}
