import { Card, Layout, Menu } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { enumException, Store } from './store';

const store = new Store();
export default function() {
  return (
    <Provider store={store}>
      <Layout className={styles.container}>
        <Layout.Sider
          collapsedWidth={0}
          collapsible
          theme="light"
          width={200}
        >
          <LeftCondition/>
        </Layout.Sider>
        <Layout.Content>
          <ContentWrapper/>
        </Layout.Content>
      </Layout>
    </Provider>
  );
}

@inject('store')
@observer
class ContentWrapper extends React.Component<{ store?: Store; }> {
  render() {
    const { state } = this.props.store;
    return (
      <>
        <Card size="small">
          <NormalProgrammeComponent store={state.normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={state.mainSubStructureModel}/>
        </div>
      </>
    );
  }
}

@inject('store')
@observer
class LeftCondition extends React.Component<{ store?: Store; }> {
  render() {
    const {
      selectedKeys,
      onSelect,
    } = this.props.store;
    return (
      <Menu
        mode="inline"
        onSelect={onSelect}
        selectedKeys={selectedKeys}
      >
        <Menu.Item key={enumException.pick.value}>
          {enumException.pick.label}
          (
          {this.props.store.pickStore.mainSubStructureModel.gridModel.total}
          条)
        </Menu.Item>
        <Menu.Item key={enumException.inventory.value}>
          {enumException.inventory.label}
          (
          {this.props.store.inventoryStore.mainSubStructureModel.gridModel.total}
          条)
        </Menu.Item>
      </Menu>
    );
  }
}

